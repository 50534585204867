<div id="about-us">
    <main>
      <section id="primary-section">
        <div class="wrapper">
          <div class="content">
            <p class="content__title">SOBRE A MEN'S</p>
            </div>
        </div>
      </section>
  
      <section id="second-section">
        <div class="wrapper">
          <img
            src="assets/img/about_block_img1.webp"
            alt="Nossa Visao"
            loading="lazy"
            class="wrapper__image"
          />
  
          <div class="wrapper__info">
            <h2>nossa visão</h2>
            <p>
                A Men's é a marca aliada no cuidado pessoal dos homens que prezam
                pelo seu bem-estar. Apresentamos uma rotina otimizada com produtos de
                qualidade, com tecnologia e eficácia, atendendo as necessidades reais do
                homem moderno.
              </p>
              <p>
                Acreditamos que para ser uma melhor versão para os outros, você precisa
                ser uma melhor versão para você mesmo e isso começa no seu autocuidado.
                Falar disso e usar bons produtos não é só coisa de mulher.
              </p>
          </div>
        </div>
  
        <div class="wrapper-2">
          <img
            src="assets/img/about_block_img2.webp"
            alt="Nossa Historia"
            loading="lazy"
            class="wrapper-2__image"
          />
  
          <div class="wrapper-2__info">
            <h2>sobre a nossa história</h2>
            <p>
                A MEN’S nasceu da Men’s Market, o e-commerce de cosméticos masculinos
                líder na América Latina. Após de avaliar e testar mais de 10 mil
                produtos, chegamos às melhores fórmulas com os ativos mais funcionais e
                efetivos. Entendemos e conhecemos bem as necessidades do público
                masculino quanto ao universo de beleza e cuidado pessoal.
            </p>
          </div>
        </div>
  
        <div class="wrapper-3">
          <img
            src="assets/img/about_block_img3.webp"
            alt="No que acreditamos"
            loading="lazy"
            class="wrapper__image"
          />
  
          <div class="vertical-block">
            <h2>No que acreditamos</h2>
            <div>
              <div class="numbers-circle">
                <span>1</span>
              </div>
              <h3>ser aliada no cuidado pessoal dos homens</h3>
            </div>
          
          <div>
            <div class="numbers-circle">
              <span>2</span>
            </div>
            <h3>descomplicar a rotina de higiene pessoal</h3>
          </div>
          <div>
            <div class="numbers-circle">
              <span>3</span>
            </div>
            <h3>entregar produtos de alta qualidade e eficácia</h3>
          </div>
          </div>
        </div>
          
      </section>

      <section id="third-section">
        <div class="container">
          <h1>
            M-8VIT: NOSSO ATIVO EXCLUSIVO
          </h1>
          <p>
            A Men's desenvolveu produtos com o objetivo de atender cada uma das
          suas necessidades de forma clara e eficaz, tornando a sua rotina de
          cuidado pessoal mais simples e fácil. Pensando nisso, criamos
          <b> o M8VIT</b>, um blend que possui ação multifuncional formada por 8
          ativos incríveis:
          </p>
          <div class="container__items">
            <div *ngFor="let recipe of recipes">
              <img 
              [src]="recipe.image"
              [alt]="recipe.name"
              >
              <p>
                <b>{{recipe.name}}</b> - {{recipe.text}}
              </p>
            </div>
          </div>
        </div>
        
      </section>
    </main>
  </div>
  