class Utils {
  static slideToSection(section: string) {
    const element = document.getElementById(section);

    if (!element) {
      return;
    }

    element.scrollIntoView({ behavior: 'smooth' });
  }
}

export { Utils };
