<div id="result-card">
    <div class="card">
        <img
            [src]="product.imagePath"
            [alt]="product.name"
        >
        <div class="card__body">
            <div>
                <div class="info">
                    <span class="info__name">{{ product.name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
