import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Shops } from '@infrab4a/connect';
import { AngularConnectModule } from '@infrab4a/connect-angular';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SwiperModule } from 'swiper/angular';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './core/services/interceptors';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HomeComponent } from './pages/home/home.component';
import { SharedModule } from './shared/shared.module';
import { FooterModule, HeaderModule, NewsletterModule, OwnBrandsValuesModule } from '@infrab4a/components-b4a';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireFunctionsModule,
    AngularConnectModule.initializeApp(Shops.MENSMARKET, {
      hasura: {
        endpoint: environment.hasura.endpoint,
        credentials: { adminSecret: environment.hasura.adminSecret }
      },
      firebase: environment.firebase,
      elasticSearch: environment.elasticSearch,
      backendUrl: environment.apiUrl
    }),
    NgxSkeletonLoaderModule.forRoot({ animation: 'progress' }),
    OwnBrandsValuesModule,
    HeaderModule,
    NewsletterModule,
    FooterModule,
    NgbModule
  ],
  providers: [
    httpInterceptorProviders,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: REGION, useValue: environment.firebaseRegion },
    {provide: 'googleTagManagerId', useValue: 'GTM-W7WZHQ3'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
