<div id="categories-component">
  <div class="wrapper">
      <h2 class="wrapper__title">Categorias</h2>

      <div class="content">
          <a
              target="_blank"
              href="https://mensmarket.com.br/collections/marca-mens?page=1&scrollY=0&profile=false&brandIds=&prices=&catFilter=cabelo"
          >
              <div class="retangle retangle__hair">

                  <span class="retangle__name">Cabelo</span>

              </div>
          </a>
          <a
              target="_blank"
              href="https://mensmarket.com.br/collections/marca-mens?page=1&scrollY=0&profile=false&brandIds=&prices=&catFilter=barba"
          >
              <div class="retangle retangle__beard">

                  <span class="retangle__name">Barba</span>

              </div>
          </a>
          <a
              target="_blank"
              href="https://mensmarket.com.br/collections/marca-mens?page=1&scrollY=40&profile=false&brandIds=&prices=&catFilter=face"
          >
              <div class="retangle retangle__face">

                  <span class="retangle__name">Face</span>

              </div>
          </a>
          <a
              target="_blank"
              href="https://mensmarket.com.br/collections/marca-mens?page=1&scrollY=40&profile=false&brandIds=&prices=&catFilter=corpo"
          >
              <div class="retangle retangle__body">

                  <span class="retangle__name">Corpo</span>

              </div>
          </a>
      </div>
  </div>
</div>
