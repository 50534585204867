export enum LeadSourceEnum {
  mens = `Mens`,
}

export interface LeadModel {
  name: string;
  email: string;
  source: LeadSourceEnum;
  acceptsNewsletter: boolean;
}
